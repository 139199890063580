import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

const Instragram = () => {
  return (
    <div style={{ maxWidth: '1000px', margin: 'auto', padding: '20px' }}>
      <h2>Instagram Post Embed Example</h2>
      
      <InstagramEmbed
        url="https://www.instagram.com/royalexplorers.leh/"  // Replace with your Instagram post URL
      />
    </div>
  );
};

export default Instragram;
