import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import './NewNavbar.css';

function NewNavbar() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isNavbarSmall, setIsNavbarSmall] = useState(false); // State to manage navbar size

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // If scrolling down, hide navbar
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setIsNavbarVisible(false);
    } else {
      // If scrolling up or at the top, show navbar
      setIsNavbarVisible(true);
    }

    // If scroll is more than 100px, make the navbar smaller
    if (currentScrollY > 100) {
      setIsNavbarSmall(true);
    } else {
      setIsNavbarSmall(false);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Navbar
      expand="lg"
      className={`fixed-top custom-navbar transition-navbar ${
        isNavbarVisible ? "visible" : "hidden"
      } ${isNavbarSmall ? "small" : ""}`}
    >
      <Container fluid  className="containerBodyNavbar">
        <Navbar.Brand href="#" className="custom-brand">
        Journey Through Ladakh
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll"  className="navbarItemsLink">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link href="#action1" className="custom-link">
              HOME
            </Nav.Link>
            <NavDropdown title="TOURS" className="custom-link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">BIKE TOUR</NavDropdown.Item>
              <NavDropdown.Item href="#action4">SUV TOUR</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#action2" className="custom-link">
              PACKAGES
            </Nav.Link>
            <Nav.Link href="#action3" className="custom-link">
              ABOUT&nbsp;US
            </Nav.Link>
            <Nav.Link href="#action4" className="custom-link">
              CONTACT
            </Nav.Link>
            
            
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2 custom-input"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NewNavbar;
