import React from 'react';
import './AboutUsNew.css'; // Link to your CSS file

const AboutUsNew = () => {
  const skills = [
    { name: "HTML", width: "95%", className: "html" },
    { name: "CSS", width: "90%", className: "css" },
    { name: "JAVASCRIPT", width: "55%", className: "javascript" },
    { name: "PHP", width: "65%", className: "php" },
    { name: "PHOTOSHOP/ILLUSTRATOR", width: "85%", className: "photoshop" },
  ];

  return (
    <div id="about" style={{ background: "#2c3e50", padding: "120px 0" }}>
      <div className="container">
        <div className="center-block head">
          <h1>About Us</h1>
          <hr className="head-border-grey" style={{ width: "20%", height: "1px" }} />
        </div>
        <div className="agency col-lg-5 col-lg-offset-1 col-md-5 col-md-offset-1">
          <p>
            Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula
            ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et.
          </p>
          <p>
            Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula
            ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et.
          </p>
          <div className="skills">
            <ul id="skillbar">
              {skills.map((skill, index) => (
                <li key={index}>
                  <span
                    className={`skillbar ${skill.className}`}
                    style={{ width: skill.width, backgroundColor: "#e67e22", height: "6px", position: "absolute" }}
                  ></span>
                  <p style={{ paddingTop: "15px", textAlign: "left", marginLeft: "2px", fontSize: "12px" }}>
                    {skill.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-5 col-md-5">
          <ul className="aboutus">
            <li>
              <img
                className="img-responsive"
                src="http://placehold.it/445x445"
                alt="YOUR TEXT HERE"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUsNew;
