import React from 'react';
import './WhyUs.css'; // Make sure to link your CSS file

const WhyUs = () => {
  const features = [
    {
      icon: "fa-globe",
      title: "Modern Design",
      description: "We use the latest technology for the latest world because we know the demand of peoples.",
      className: "feature_box_col_one",
    },
    {
      icon: "fa-anchor",
      title: "Creative Design",
      description: "We are always creative and listen to our customers, mixing these two things to make the best designs.",
      className: "feature_box_col_two",
    },
    {
      icon: "fa-hourglass-half",
      title: "24 x 7 User Support",
      description: "If our customers have any problem or query, we are always happy to help them.",
      className: "feature_box_col_three",
    },
    {
      icon: "fa-database",
      title: "Business Growth",
      description: "Everyone wants to live on top of the mountain, but all the happiness and growth occur while climbing it.",
      className: "feature_box_col_four",
    },
    {
      icon: "fa-upload",
      title: "Market Strategy",
      description: "Holding back technology to preserve broken business models is like allowing blacksmiths to veto the internal combustion engine.",
      className: "feature_box_col_five",
    },
    {
      icon: "fa-camera",
      title: "Affordable Cost",
      description: "Love is a special word, and I use it only when I mean it. Overuse of the word makes it lose value.",
      className: "feature_box_col_six",
    },
  ];

  return (
    <div className="feat bg-gray pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="section-head col-sm-12">
            <h4>
              <span>Why Choose</span> Us?
            </h4>
            <p>
              When you choose us, you'll feel the benefit of 10 years' experience in Web Development. We know the digital
              world and how to handle it, with expertise in online platforms, SEO, and social media.
            </p>
          </div>
          {features.map((feature, index) => (
            <div key={index} className="col-lg-4 col-sm-6">
              <div className="item">
                <span className={`icon ${feature.className}`}>
                  <i className={`fa ${feature.icon}`}></i>
                </span>
                <h6>{feature.title}</h6>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
