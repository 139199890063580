import React, { useEffect, useState } from "react";

import "./LandingPage.css";
import { Helmet } from "react-helmet";
import ScrollAnimation from "../../Molecules/Animated/ScrollAnimation";
import LandingSecondPage from "./LandingSecondPage";
import LandingGallery from "./LandingGallery";
import Services from "../ServicesOffered/Services";
import Testimonal from "../../Pages/Testimonal/Testimonal";
import Footer from "../../Organisms/Footer/Footer";

import ImageHover from "./ImageHover/imageHover";
import LandingGalleryMobile from "./LandingGalleryMobile";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import LandingPageGalleryCarousel from "./LandingPageGalleryCarousel";
import Facilities from "./Facilities";
import PackageDetail from "../Packages/PackageDetail";
import ServiceCards from "../../Organisms/ServiceCard/ServiceCards";
import LandingPackagesBike from "./LandingPackagesBike";
import FullPageCarousel from "./FullPageCarousel";
import GoogleReviews from "./GoogleReviews";
import Instragram from "./Instragram";
import LandingVideo from "./LandingVideo";
import WhyUs from "../WhyChooseUs/WhyUs";
import AboutUsNew from "../AboutUs/AboutUsNew";
const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.outerWidth]);
  return (
    <div>
    <Helmet>
  <title>
    Ladakh Heights Hotel | Luxury Accommodations in the Heart of the Himalayas
  </title>
  <meta
    name="description"
    content="Experience luxury and adventure at Ladakh Heights Hotel. Nestled amidst breathtaking mountain landscapes, our hotel offers elegantly appointed rooms and suites, world-class amenities, and unforgettable experiences. Book your stay today and discover the magic of Ladakh!"
  />
  <meta
    name="keywords"
    content="Ladakh Heights Hotel, luxury hotel Ladakh, hotel in Leh, best stay in Ladakh, best hotel, luxury accommodation, adventure hotel, Himalayas hotel, Ladakh hotel, Ladakh accommodation"
  />
  <meta name="robots" content="index, follow" />
</Helmet>

      <ScrollToTop />


      <div className="landingPageBody">
        <div>
          {/* {isMobile ? <LandingGalleryMobile /> : <LandingGallery />} */}

          {/* <div
            className="mask"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          ></div> */}
        </div>
        {/* <div className="backgroundImageBody">
          <div className="background-containerLanding">
            <div></div>
            <div
              className={isMobile ? "landingTextBodyMbl" : "landingTextBody"}
            >
              <div className="landingBackgroundHeading">
                <ScrollAnimation>
                  <h6
                    className={
                      isMobile ? "landingPageHeadingMbl" : "landingPageHeading1"
                    }
                  >
                    Book your dream holiday with us
                  </h6>
                </ScrollAnimation>
                <div className="landingBackgroundSubheading">
                  <br />
                  <i
                    className={
                      isMobile
                        ? "landingPageHeading2Mbl"
                        : "landingPageHeading2"
                    }
                  >
                    Elevating Your Stay, One Experience at a Time
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <LandingVideo/>
        {/* <FullPageCarousel/> */}

{/* <ServiceCards/> */}
        <LandingSecondPage />
       
      </div>
      {/* <PackageDetail/> */}
      <LandingPackagesBike/>
      <LandingPageGalleryCarousel />
      <ImageHover />
      <Services />
      <Instragram/>
      <WhyUs/>
      <AboutUsNew/>
      {/* <GoogleReviews/> */}
      {/* <Facilities/> */}
      <Testimonal />
      <Footer />
    </div>
  );
};

export default LandingPage;
