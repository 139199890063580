import React from 'react';
import ReactPlayer from 'react-player';
import './LandingVideo.css';  // Make sure you create this CSS file for styling

const LandingVideo = () => {
  return (
    <div className="landing-page">
      <div className="video-background">
        {/* Use ReactPlayer for the YouTube video */}
        <ReactPlayer
          url="https://www.youtube.com/watch?v=I7-ihKi218w" // Replace with your YouTube video URL
          playing={true}
          loop={true}
          muted={true}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </div>

      <div className="content">
        <h1>Welcome to Our Website</h1>
        <p>Explore our amazing services!</p>
      </div>
    </div>
  );
};

export default LandingVideo;
