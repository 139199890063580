import React, { useEffect, useState } from "react";

import './LandingPageGalleryCarousel.css'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useNavigate } from 'react-router-dom'
import package1 from "../../../assets/jig2.jpeg"
import package2 from "../../../assets/CAMPS.jpeg"
import package3 from "../../../assets/bike1.jpeg"
import package4 from "../../../assets/mon.jpeg"

import AccessTimeIcon from '@mui/icons-material/AccessTime';

const packages = [
  {
    id: 1,
    packageName: 'Leh to Leh 05 Days trip',
    Price: '11,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh ',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 600 approx',
      'Duration – 04 Nights – 05 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (3N)→Nubra Valley (1N) ',
      'Leh : Himalayan Retreat karzoo / Similar',
      ' Nubra : Royal delux camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1: Arrival at Leh Airport',
        answer: [
          'Welcome to Ladakh, a unique land stretching from the Glacier to the main Great Himalayas.',
          'Your journey begins with your arrival at Leh Airport',
          'Leh, the capital and largest town of Ladakh, awaits you. The Journey Through Ladakh Team will be there to greet you at the airport and escort you to your hotel for check-in.',
          'Today is designated for settling in and resting before we embark on exploring the breathtaking landscapes and roads.',
          'In the evening, our team leader will meet you at the hotel to provide a briefing about the trip.',
          'Overnight accommodation at a hotel in Leh.'
        ]
      },
      {
        question: 'Day 2: Leh Local Sightseeing towards Sham Valley',
        answer: [
          'Today’s sightseeing spans approximately 90 kilometers.',
          'Following a leisurely breakfast, we will explore Magnetic Hill, Pathar Sahib Gurudwara, and the Hall of Fame (a museum honoring courageous Indian soldiers).',
          'We will also visit Sangam Point, the meeting point of the Indus and Zanskar rivers.',
          'In the evening, we will take a trip to Shanti Stupa and Leh Palace.',
          'Overnight accommodation in Leh.'
        ]
      },
      {
        question: 'Day 3: Leh to Nubra Valley via Khardung-La Pass',
        answer: [
          'Travel distance and time: We will cover approximately 130 kilometers in about 5 to 6 hours.',
          'Our journey will take us through the majestic Khardung La Pass, standing at an impressive altitude of 17,982 feet, making it the highest motorable road.',
          'The valley we will traverse is renowned for its sand dunes adorned with white rolling sands, offering a captivating sight of the double-humped Bactrian Camels.',
          'At Diskit Monastery, a remarkable 33-meter statue of Maitreya Buddha overlooks the Shayok River.',
          'Nubra Valley presents numerous adventurous opportunities, including ATV bike rides, zip-lining, rafting, go-karting, and double-humped camel rides.',
          'Overnight accommodation awaits us at Swiss camps in Hunder.'
        ]
      },
      {
        question: 'Day 4: Nubra Valley to Leh via Pangong Lake',
        answer: [
          'Our journey takes us towards Leh via Pangong Lake, situated in the eastern part of Ladakh near the border with China.',
          'The travel distance covers approximately 360 kilometers and is expected to take around 12 to 13 hours.',
          'By afternoon, we will arrive at the lake, nestled amidst the mountains, offering a stunning surprise with its beauty.',
          'Take some time to cherish the serene surroundings.',
          'On our return journey to Leh, we will pass through Chang La, standing at an elevation of 5360 meters.',
          'Overnight accommodation awaits us in Leh.'
        ]
      },
      {
        question: 'Day 5: Departure for Home',
        answer: [
          'Following breakfast, we will depart for Leh Airport, marking the end of your journey with us. It is time for your departure.',
          'We sincerely hope that you carry with you joyful and vivid memories of this captivating and enigmatic landscape.',
          'We eagerly await the opportunity to welcome you back to Ladakh in the future.'
        ]
      }
    ],
    Include: [
      'Tempo Traveller / Mahindra Xylo',
      '04 Nights Accommodation on double or triple sharing basis as per the package selected ( 03 Nights in Leh / 01 Night Nubra ).',
      'Daily buffet breakfast & dinner served at all places.',
      'Daily morning tea / coffee',
      'Daily evening tea / coffee with cookies',
      'Assistance on arrival',
      'Oxygen Cylinder in each vehicle while travelling to Nubra and Pangong only',
      'Inner Line Permit',
      'Environmental, and Wildlife Fee',
      'Airport pickup & drop'
    ],
    exclude : [
      'Airfare',
      'Goods and Services Tax (GST) 5%',
      'First day breakfast',
      'Lunch throughout the tour',
      'Any entry fee for the monument and monastery',
      'Any Kind of adventure activities',
      'Tip for the Supported Team',
      'Travel insurance',
      'Any item of personal requirement, such as drinks, laundry, telephone bills, etc',
      'Anything not mentioned'
    ],
    tempo12: '10,999',
    tempo8: '11,499',
    suv6: '11,999',
    suv4: '14,499',
    suv2: '20,499',




  },
  {
    id: 2,
    packageName: 'Leh to Leh 06 Days trip',
    Price: '11,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 600 approx',
      'Duration – 05 Nights – 06 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (3N)→Nubra Valley (1N) -> Pangong (1N)',
      'Leh : Himalayan Retreat karzoo / Similar',
      ' Nubra : Royal delux camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1: Arrival at Leh Airport',
        answer: [
          'Welcome to Ladakh, a unique region extending from the Glacier to the main Great Himalayas.',
          'Your journey kicks off with your arrival at Leh Airport.',
          'Leh, the capital and largest town of Ladakh, awaits your arrival. The Journey Through Ladakh Team will be present at the airport to welcome you and assist you to your hotel for check-in.',
          'Today is set aside for settling in and resting before we begin exploring the stunning landscapes and roads.',
          'Later in the evening, our team leader will convene with you at the hotel to provide a briefing about the trip.',
          'Overnight accommodation will be provided at a hotel in Leh.'
        ]
      },
      {
        question: 'Day 2: Leh Local Sightseeing towards Sham Valley',
        answer: [
          "Today's sightseeing covers around 90 kilometers.",
          'After a relaxed breakfast, our itinerary includes visits to Magnetic Hill, Pathar Sahib Gurudwara, and the Hall of Fame, a museum paying tribute to brave Indian soldiers.',
          "Additionally, we'll explore Sangam Point, where the Indus and Zanskar rivers converge.",
          'In the evening, our excursion extends to Shanti Stupa and Leh Palace.',
          'Accommodation for the night will be provided in Leh.'
        ]
      },
      {
        question: 'Day 3: Leh to Nubra Valley via Khardung-La Pass',
        answer: [
          "Travel distance and time: We'll be covering approximately 130 kilometers over a span of about 5 to 6 hours.",
          ' Our journey will lead us through the majestic Khardung La Pass, towering at an impressive altitude of 17,982 feet, marking it as the highest motorable road.',
          "The valley we'll traverse is renowned for its sand dunes adorned with white rolling sands, providing a captivating view of the double-humped Bactrian Camels.",
          'At Diskit Monastery, an impressive 33-meter statue of Maitreya Buddha watches over the Shayok River.',
          'Nubra Valley offers a plethora of adventurous activities, including ATV bike rides, zip-lining, rafting, go-karting, and rides on double-humped camels.',
          'Accommodation for the night awaits us at Swiss camps in Hunder.'
        ]
      },
      {
        question: 'Day 4: Nubra Valley to Pangong Lake via Shayok Route',
        answer: [
          'Our journey will take us towards Pangong Lake via the Shayok Route, located to the east of Ladakh, near the border with China.',
          'The travel time and distance will be approximately 7-8 hours covering a distance of around 180 kilometers.',
          'By evening, we will arrive at the lake and be amazed by its stunning beauty nestled amidst the mountains.',
          'Pangong Tso, also known as the high grassland lake, is a saltwater lake situated at a high altitude of 4,225 meters.',
          "The lake is remarkably vast, with 70% of its expanse lying within China's territory and the remaining 30% within India.",
          'Overnight accommodation will be provided in cottages at Pangong.'
        ]
      },
      {
        question: 'Day 5: Pangong Lake to Leh via Changla Pass',
        answer: [
          'Travel distance and time: We will cover approximately 180 kilometers in a span of 6-7 hours.',
          "Today, we will commence our journey back to Leh. Early in the morning, you'll have the opportunity to capture a stunning sunrise time-lapse at Pangong Lake.",
          'En route, we will traverse Chang La, standing at an elevation of 5360 meters.',
          'Our itinerary includes visits to Thiksey Monastery, constructed in 1430 AD, and Rancho’s school.',
          'Accommodation for the night will be provided in Leh.'
        ]
      },
      {
        question: 'Day 6: Departure for Home',
        answer: [
          'Following breakfast, we will depart for Leh Airport, marking the end of your journey with us. It is time for your departure.',
          'We sincerely hope that you carry with you joyful and vivid memories of this captivating and enigmatic landscape.',
          'We eagerly await the opportunity to welcome you back to Ladakh in the future.'
        ]
      }
    ],
    Include: [
      'Tempo Traveller / Mahindra Xylo',
      '04 Nights Accommodation on double or triple sharing basis as per the package selected ( 03 Nights in Leh / 01 Night Nubra ).',
      'Daily buffet breakfast & dinner served at all places.',
      'Daily morning tea / coffee',
      'Daily evening tea / coffee with cookies',
      'Assistance on arrival',
      'Oxygen Cylinder in each vehicle while travelling to Nubra and Pangong only',
      'Inner Line Permit',
      'Environmental, and Wildlife Fee',
      'Airport pickup & drop'
    ],
    exclude : [
      'Airfare',
      'Goods and Services Tax (GST) 5%',
      'First day breakfast',
      'Lunch throughout the tour',
      'Any entry fee for the monument and monastery',
      'Any Kind of adventure activities',
      'Tip for the Supported Team',
      'Travel insurance',
      'Any item of personal requirement, such as drinks, laundry, telephone bills, etc',
      'Anything not mentioned'
    ],
    tempo12: '12,999',
    tempo8: '14,499',
    suv6: '14,999',
    suv4: '16,499',
    suv2: '23,999',
  },
  {
    id: 3,
    packageName: 'Leh to Leh 07 Days trip',
    Price: '15,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 700 approx',
      'Duration – 06 Nights – 07 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (4N)→ Nubra Valley (1N) → Pangong (1N)',
      'Leh : Himalayan Retreat karzoo / Similar',
      ' Nubra : Royal delux camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1: Leh (2 Nights)',
        answer: [
          'Guests arrive at Leh Airport according to their scheduled flight.',
          'They are met by the Journey Through Ladakhtour manager and transferred to the hotel.',
          'Free time is allotted for acclimatization upon arrival.',
          'In the evening, a short Meet & Greet session is organized to welcome the guests.'
        ]
      },
      {
        question: 'Day 2: Leh Sightseeing',
        answer: [
          "Visit Shey Palace, once the summer retreat of Ladakh's royal family.",
          'Explore Rancho School, also known as Druk White Lotus School.',
          'Discover Hemis Gompa, a prominent Himalayan Buddhist monastery, reputedly the wealthiest and largest in Leh.',
          'Take a photo stop at Thiksey Monastery.',
          "Witness Sindhu Darshan, a view of the Sindhu River, one of Asia's longest rivers.",
          'Explore the Hall of Fame War Museum, dedicated to the brave Indian soldiers who sacrificed their lives in Indo-Pak wars.',
          'Enjoy some free time for shopping later in the day.'
        ]
      },
      {
        question: 'Day 3: Leh – Khardung La Pass – Nubra Valley (1 Night)',
        answer: [
          'Depart for Nubra Valley via Khardung La, renowned as one of the world’s highest motorable passes.',
          'Nubra Valley, where the Shyok River meets the Siachen River, creating a vast valley that separates the Ladakh and Karakoram ranges.',
          'Upon arrival, visit Maitreya Buddha, a remarkable statue of Jampa Buddha perched atop a monastery. Admire its intricate carvings, brilliant craftsmanship, and vibrant colors.',
          'Explore Hunder Village and the Cold Desert, the exclusive habitat of twin-humped camels in India.',
          'Please note: Khardung La Pass is situated at a high altitude, which may pose challenges for guests with certain health conditions. It is advisable to consult with a doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 4: Nubra Valley – Pangong Tso (1 Night)',
        answer: [
          "Journey to Pangong Tso, also known as Pangong Lake, renowned as one of the world's highest saltwater lakes.",
          "Marvel at the lake's most notable feature: its ever-changing hues, which captivate visitors with their beauty.",
          'Explore the surroundings, as Pangong Lake serves as an important breeding ground for a diverse range of migratory birds, including Brahmani Ducks, Rare Black-necked Cranes, and Seagulls.',
          'Please note: Pangong Tso is situated at a high altitude, which may pose challenges for guests with certain health conditions. It is strongly advised to consult with a doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 5: Pangong Tso – Leh (2 Nights)',
        answer: [
          'Depart from Pangong Tso and head towards Leh via Changla Pass, renowned as one of the highest motorable roads in the world.',
          'The name "Changla Pass" translates to "Northern Pass" (Chang meaning north and La meaning Pass).',
          'Please note: Changla Pass is situated at a high altitude, which may present challenges for guests with certain health conditions. It is highly recommended to consult with your doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 6: Leh',
        answer: [
          'Visit Patthar Sahib Gurudwara, a beautiful Gurudwara Sahib built in memory of Guru Nanak.',
          'Explore Magnetic Hill, also known as Gravity Hill, where vehicles appear to defy the force of gravity.',
          'Witness the Sangam of the rivers Indus & Zanskar, where the two great Himalayan rivers merge, offering a spectacular view.',
          'Visit Shanti Stupa, constructed by Japanese monks to promote world peace.',
          'In the evening, enjoy a Folk dance show.'
        ]
      },
      {
        question: 'Day 7: Leh - Departure',
        answer: [
          'Bid farewell to travel companions and exchange contact information for staying in touch through email, phone, WhatsApp, Facebook, and Instagram.',
          'Guests will proceed to Leh airport as per their scheduled flight and begin their return journey back home.',
          'Tour concludes.',
          'Please note: Guests must adhere to mandatory travel requirements such as RT-PCR tests, vaccination certificates, web check-in, self-declaration, e-pass formalities, etc., as per the state/country regulations, subject to periodic changes. Any costs incurred for such requirements are to be borne by the guests.',
          'Transportation for transfers and sightseeing will be arranged based on the respective tour group size, using small cars, Tempo Travellers, or mini/big coaches. In small cars, there will be 4-6 guests per car. Guests can opt for exclusive vehicle usage at an additional cost, subject to availability.',
          'Due to the current dynamic situation, some monuments or sightseeing places may be temporarily closed without prior notice. Every effort will be made to visit alternate sightseeing spots if possible.'
        ]
      }
    ],
    Include: [
      'Tempo Traveller / Mahindra Xylo',
      '04 Nights Accommodation on double or triple sharing basis as per the package selected ( 03 Nights in Leh / 01 Night Nubra ).',
      'Daily buffet breakfast & dinner served at all places.',
      'Daily morning tea / coffee',
      'Daily evening tea / coffee with cookies',
      'Assistance on arrival',
      'Oxygen Cylinder in each vehicle while travelling to Nubra and Pangong only',
      'Inner Line Permit',
      'Environmental, and Wildlife Fee',
      'Airport pickup & drop'
    ],
    exclude : [
      'Airfare',
      'Goods and Services Tax (GST) 5%',
      'First day breakfast',
      'Lunch throughout the tour',
      'Any entry fee for the monument and monastery',
      'Any Kind of adventure activities',
      'Tip for the Supported Team',
      'Travel insurance',
      'Any item of personal requirement, such as drinks, laundry, telephone bills, etc',
      'Anything not mentioned'
    ]
    ,
    tempo12: '14,999',
    tempo8: '16,999',
    suv6: '17,999',
    suv4: '20,499',
    suv2: '28,850',

  },
  {
    id: 4,
    packageName: 'Leh to Leh 08 Days trip',
    Price: '29,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Chushul – Rezang La war memorial – Tsaga village – Loma bridge – Hanle – Nyoma – Chumathang – Leh ',
    passesCover: 'Khardung La (17,982 ft) – Tsaga La (15242 ft) – Umling La (19,300 ft)  ',
    overview: [
      'Area – Ladakh',
      'Kilometer – 920 approx',
      'Duration – 07 Nights – 08 Days',
      'Max Altitude – 19,300 ft',
      'Number of Passes – 03',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (2N)→Nubra Valley (1N)→Pangong (1N)→Hanle (2N)→Leh (1N)',
      'Leh : Hotel Himalayan Retreat karzoo / Similar',
      ' Nubra : Royal delux Camp / Similar',
      'Pangong : De Reeva Cottage / Similar',
     'Hanle : Homestay',
    ],
    itineary: [
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Ladakh, spanning from the Glacier to the main Great Himalayas, is a region like none other.',
          'Your adventure commences upon your arrival at Leh Airport. Leh, the capital and largest town of Ladakh, awaits your arrival. The Journey Through Ladakh Team will greet you at the airport and transfer you to the hotel for check-in.',
          'Today is dedicated to allowing you to settle in and rest before we embark on exploring the breathtaking landscapes and roads tomorrow.',
          'In the afternoon, our Supported Team leader will convene with you at the hotel to provide a briefing about the trip.',
          'You will receive your bikes and riding gear at the hotel.',
          'Accommodation for the night will be provided at a hotel in Leh.'
        ]
      },
      {
        question: 'Day 2  Leh Local Sightseeing towards Sangam Point',
        answer: [
          'Today’s ride will cover approximately 90 kilometers.',
          'Terrain type: Basic',
          'Following a leisurely breakfast, our itinerary includes visits to Magnetic Hill, Pathar Sahib Gurudwara, and the Hall of Fame, a museum dedicated to the courageous Indian soldiers.',
          'We will also explore Sangam Point, where the Indus and Zanskar rivers meet.',
          'In the evening, we will take a trip to Shanti Stupa.',
          'Accommodation for the night will be provided in Leh.'
        ]
      },
      {
        question: 'Day 3 Leh to Nubra Valley via Khardung-La Pass',
        answer: [
          'Travel distance and time: We will cover approximately 130 kilometers over a duration of 5-6 hours.',
          'Terrain type: Intermediate',
          'Our route takes us through the legendary Khardung La Pass, standing at an elevation of 17,982 feet, renowned as the highest motorable road.',
          'The valley we traverse is famed for its sand dunes adorned with white rolling sands and the mesmerizing sight of double-humped Bactrian Camels.',
          "At Diskit Monastery, you'll encounter a remarkable 33-meter statue of Maitreya Buddha overlooking the Shayok River.",
          'Nubra Valley offers abundant adventurous opportunities. You can engage in ATV bike rides, zip-lining, rafting, go-karting, and rides on double-humped camels.',
          'Accommodation for the night awaits us at Swiss camps in Hunder.'
        ]
      },
      {
        question: 'Day 4 Nubra Valley to Pangong Lake via Shayok Route',
        answer: [
          'We will ride towards Pangong Lake via Shayok Route (east of Ladakh ), on the border with China.',
          'Travel time and distance (7-8 hours /180km approx).',
          'Terrain Type (advance level as most of the terrain will be off-roading and water crossings will be there.)',
          'You will reach the Lake by evening and you will be surprise to see such a beautiful lake in the middle of mountains.',
          'Pangong Tso (high grassland lake) is a high altitude salt water lake, the lake is so huge that 70% of it is in China and 30% is in India.',
          'Pangong Lake altitude 4,225m.',
          'Overnight stay at Cottage in Pangong .'
        ]
      },
      {
        question: 'Day 5 Pangong to Hanle via Rezang La war memorial',
        answer: [
          'Travel distance and time: Our journey will span approximately 200 kilometers and take around 6-7 hours.',
          'Terrain type: Intermediate',
          'This day offers the opportunity to witness the true beauty of Pangong Lake.',
          'In Chushul, we will pay a visit to the Rezang La War Memorial.',
          'Hanle boasts a charming monastery that provides stunning aerial views of the entire village. The village is home to around 1000 people and sits at an altitude of 4500 meters.',
          'The Hanle Observatory, operated by the Indian Institute of Astrophysics in Bengaluru, is also located here. It currently holds the title of being the second highest optical observatory globally, with an installed telescope boasting a diameter of about 2.01 meters (6.5 feet).',
          'Accommodation for the night will be provided in Hanle.'
        ]
      },
      {
        question: 'Day 6 Hanle to Umling La and back to Hanle',
        answer: [
          "Travel distance and time: Today's journey spans approximately 180 kilometers and will take about 6-7 hours.",
          'Terrain type: Intermediate',
          "Today's highlight includes riding on the highest motorable road in the world.",
          'Umling La is a mountain pass located in Ladakh, India, situated on the ridgeline between Koyul Lungpa and the Indus River near Demchok. It stands at an elevation of 5,640 meters and serves as the source for the Umlung stream, which drains into the Indus, and a tributary of the Kiungdul river, which drains into Koyul Lungpa.',
          'We will spend the night at Hanle.'
        ]
      },
      {
        question: 'Day 7 Hanle to Leh via Chumathang',
        answer: [
          'Travel distance and time: Our journey covers approximately 250 kilometers and is expected to take around 7-8 hours.',
          'Terrain Type: Intermediate',
          "Along the way, you'll have the opportunity to explore Chumathang, known for its hot springs.",
          "We'll have a lunch break at Upshi village.",
          'Our itinerary also includes visits to Thiksey Monastery and Rancho School.',
          'Accommodation for the night will be provided in Leh.'
        ]
      },
      {
        question: 'Day 8 Departure to Home',
        answer: [
         "Early morning transfer to Leh airport to board flight back to destination. Journey ends with Sweet Memories of Ladakh.",
        ]
      }
    ],
    Include: [
      'Tempo Traveller / Mahindra Xylo',
      '04 Nights Accommodation on double or triple sharing basis as per the package selected ( 03 Nights in Leh / 01 Night Nubra ).',
      'Daily buffet breakfast & dinner served at all places.',
      'Daily morning tea / coffee',
      'Daily evening tea / coffee with cookies',
      'Assistance on arrival',
      'Oxygen Cylinder in each vehicle while travelling to Nubra and Pangong only',
      'Inner Line Permit',
      'Environmental, and Wildlife Fee',
      'Airport pickup & drop'
    ],
    exclude : [
      'Airfare',
      'Goods and Services Tax (GST) 5%',
      'First day breakfast',
      'Lunch throughout the tour',
      'Any entry fee for the monument and monastery',
      'Any Kind of adventure activities',
      'Tip for the Supported Team',
      'Travel insurance',
      'Any item of personal requirement, such as drinks, laundry, telephone bills, etc',
      'Anything not mentioned'
    ]
    ,
    tempo12: '14,999',
    tempo8: '16,999',
    suv6: '17,999',
    suv4: '20,499',
    suv2: '28,850',
  }
]


const carouselData = [
  {
    img: package1,
    title: 'Leh to Leh 05 Days Trip',
    text: 'Leh to leh 4N/5D',
    realPrice: '15,000',
    discountPrice: '10,999',
    days:'5',
  },
  {
    img: package2,
    title: 'Leh Nubra Pangong 06 DaysTrip',
    text: 'Discover the ladakh on bike',
    realPrice: '16,000',
    discountPrice: '12,999',
    days:'6',

  },
  {
    img: package3,
    title: 'Leh Nubra Pangong 07 DaysTrip',
    text: 'Discover the ladakh on bike',
    realPrice: '18,000',
    discountPrice: '14,999',
    days:'7',

  },
  {
    img: package4,
    title: 'Leh to Leh with Umling La Trip',
    text: 'Discover the ladakh on bike',
    realPrice: '20,500',
    discountPrice: '17,499',
    days:'8',


  }
]

export default function LandingPageGalleryCarousel () {
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.outerWidth]);
  const handleClick = id => {
    console.log('id', id)
    navigate(`/packageDetail/${id}`, { state: packages })
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,

    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }
  return (
    <div className='galleryCarouselBody'>
      <div className='text-center1'>
        <h2>SUV TOURS</h2>
        <i className='textHeadingTestimonal'>
        Discover the Untamed Beauty of Ladakh with Our Unforgettable {' '}
          <span className='ladakhColor'> Tour Packages! </span>
        </i>
      </div>
      <div>
        <Slider {...settings}>
          {carouselData.map((service, index) => (
            <div
              key={index}
              class='card'
              style='width: 18rem;'
            >
              <img src={service.img} class='card-img-top' alt='...' />

              <div class='card-body'>
                <h5 class='card-title'>{service.title}</h5>
                <p style={{fontSize:isMobile ? "4vw":"1vw", color:"grey"}} class='card-text'>{service.text}</p>
              </div>
              <div className='daysPackageIcon'>
                <div>
                <AccessTimeIcon/><span>{service.days} Days</span>
                </div>
                <div className='priceBlock'>
                  <del className='pricePackageIcon'>
                  ₹{service.realPrice}

                  </del>
                  <span className='realprice'>

                  ₹{service.discountPrice}
                  </span>
                </div>
             

              </div>
             
              <div style={{display:"flex", justifyContent:"space-between"}} class='card-body'>
                <a  href={`https://wa.me/919906697810?text=Hello, I want to book this ${service.title} package.`} target="_blank" class='card-link'>
                 Send Query
                </a>
                <a onClick={() => handleClick(index)} class='card-link'>
                 View Details
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className='viewMoreRoom'>
        {/* <Link to='Gallery'>View More</Link> */}
      </div>
    </div>
  )
}
